'use client';

import type { ReactNode } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from '@/locales/en.json';
import viJson from '@/locales/vi.json';
i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJson
    },
    vi: {
      translation: viJson
    }
  },
  lng: 'vi',
  fallbackLng: 'vi',
  interpolation: {
    escapeValue: false
  }
});
const ReactI18nextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  return <>{children}</>;
};
export default ReactI18nextProvider;